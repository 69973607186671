
import { useEffect, useState } from "react";
import Table from "../../components/Table"
import { Container } from "./styles"
import { setDate, format } from "date-fns";
import { useDashboard } from "../../context/DashboardContext";
import ReactDatePicker from "react-datepicker";
import Button from "../../components/Button";
import { CSVLink } from "react-csv";
import { ResponseReferFriendsReport } from "../../interfaces/ResponseReport";
import { formatDataToCSVReferFriend } from "../../utils/exportsCSV";

const columns = [
  // dados do indicado
  { title: "Nome (indicado)", attribute_name_tbody: "indication_name" },
  { title: "Empreendimento (indicado)", attribute_name_tbody: "indication_enterprise_name" },
  { title: "Bloco do Empreendimento (indicado)", attribute_name_tbody: "indication_enterprise_block" },
  { title: "Unidade (indicado)", attribute_name_tbody: "indication_enterprise_unit" },
  { title: "Data da ass. contrato (indicado)", attribute_name_tbody: "indication_contract_signing_date" },
  { title: "Data da venda (indicado)", attribute_name_tbody: "indication_sale_date" },
  { title: "Num. Venda (indicado)", attribute_name_tbody: "indication_sale_number" },
  { title: "CPF (indicado)", attribute_name_tbody: "indication_document" },
  { title: "Email (indicado)", attribute_name_tbody: "indication_email" },
  { title: "Número (indicado)", attribute_name_tbody: "indication_phone_number" },
  { title: "Cód. Empreendimento (indicado)", attribute_name_tbody: "indication_enterprise_code" },
  { title: "Email Imobiliária (indicado)", attribute_name_tbody: "indication_agency_email" },
  { title: "Imobiliária (indicado)", attribute_name_tbody: "indication_agency_name" },
  // dados do indicador
  { title: "Nome (indicador)", attribute_name_tbody: "indicator_name" },
  { title: "CPF (indicador)", attribute_name_tbody: "indicator_document" },
  { title: "Num. Venda (indicador)", attribute_name_tbody: "indicator_sale_number" },
  { title: "Empreendimento (indicador)", attribute_name_tbody: "indicator_enterprise_name" },
  { title: "Bloco do Empreendimento (indicador)", attribute_name_tbody: "indicator_enterprise_block" },
  { title: "Unidade (indicador)", attribute_name_tbody: "indicator_enterprise_unit" },
  { title: "Data da ass. contrato (indicador)", attribute_name_tbody: "indicator_contract_signing_date" },
  { title: "Data da venda (indicador)", attribute_name_tbody: "indicator_sale_date" },
  { title: "Imobiliária (indicador)", attribute_name_tbody: "indicator_agency_name" },
  { title: "Email Imobiliária (indicador)", attribute_name_tbody: "indicator_agency_email" },
  { title: "Cód. Empreendimento (indicador)", attribute_name_tbody: "indicator_enterprise_code" },

  { title: "Link compartilhado", attribute_name_tbody: "shared_link" },
  { title: "Conta", attribute_name_tbody: "account_name" },
  { title: "Número da conta", attribute_name_tbody: "account_number" },
  { title: "Agência", attribute_name_tbody: "bank_branch" },
  { title: "Código do banco", attribute_name_tbody: "bank_code" },
  { title: "Nome do banco", attribute_name_tbody: "bank_name" },
  { title: "Chave Pix", attribute_name_tbody: "pix_key" },
  { title: "Tipo da chave pix", attribute_name_tbody: "type_pix_key" },

  { title: "Status", attribute_name_tbody: "status" },
  { title: "Pago", attribute_name_tbody: "paid" },
  { title: "Data da indicação", attribute_name_tbody: "indication_date" },
];

const ReferFriends = () => {
  const { requestReferFriendReport } = useDashboard();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState<ResponseReferFriendsReport[]>([] as ResponseReferFriendsReport[]);
  const [dataCSV, setDataCSV] = useState<any[]>([]);

  const [dateFilter, setDateFilter] = useState<(Date | null)[]>([
    setDate(new Date(), 1),
    new Date(),
  ]);

  const handleSelectDate = (param: any) => {
    setDateFilter(param);
  }

  const handleFilter =() => {
    if (dateFilter[0] && dateFilter[1]) {
      setIsLoading(true)
      requestReferFriendReport(dateFilter[0].toDateString(), dateFilter[1].toDateString())
          .then((res) => {
            const formatted = res.map(item => ({
              ...item,
              paid: item.has_paid ? 'Sim' : 'Não',
              indication_date: format(new Date(item.indication_date), 'dd/MM/yyyy'),
              indication_contract_signing_date:
                item.indication_contract_signing_date &&
                format(new Date(item.indication_contract_signing_date), 'dd/MM/yyyy'),
              indication_sale_date: item.indication_sale_date &&
                format(new Date(item.indication_sale_date), 'dd/MM/yyyy'),
              indicator_contract_signing_date:
                item.indicator_contract_signing_date &&
                format(new Date(item.indicator_contract_signing_date), 'dd/MM/yyyy'),
              indicator_sale_date: item.indicator_sale_date &&
                format(new Date(item.indicator_sale_date), 'dd/MM/yyyy'),
            }))
            setData(formatted);
            setDataCSV(formatDataToCSVReferFriend(formatted));
            setIsLoading(false);
          });
      }
  }

  useEffect(() => {
    handleFilter()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <Container>
      <div className="date-filter">
          <label htmlFor="date-filter">Filtrar data</label>
          <ReactDatePicker
              startDate={dateFilter[0]}
              endDate={dateFilter[1]}
              onChange={(e) => handleSelectDate(e)}
              selectsRange
              locale="pt-BR"
              dateFormat="dd/MM/yyyy"
          />
           <div className="fetch">
              <Button onClick={handleFilter}>
                  Buscar
              </Button>
          </div>
      </div>
      <div className="table">
        <div>
          <h2>Indique um amigo</h2>
        </div>
        <div className="data-export">
          <CSVLink filename={"Indique um amigo " + format(new Date(), "dd-MM-yyyy-HH-mm")} data={dataCSV}>Exportar CSV</CSVLink>
        </div>
      </div>
      <div className="content">
        <Table
          columns={columns}
          data={data}
          isLoading={isLoading}
          offset={15}
          nowrapCell={["cpf"]}
        />
      </div>
    </Container>
  )
}


export default ReferFriends
